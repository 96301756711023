import { useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';

import gif from '../assets/javali.gif';

import './styles.css'

export function Home(){
  const [videoHeight, setVideoHeight] = useState(0)

  const screenRef = useRef(null)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const link = queryParams.get('link');

  return (
    <div 
      className='bg-white w-full h-full overflow-hidden max-h-svh' 
      ref={screenRef}
      onLoad={() => {
        setVideoHeight(screenRef.current?.clientHeight)
      }}
      >
        <div 
          className="relative flex max-h-svh h-full overflow-hidden w-full flex-col bg-black items-center justify-center" 
          >
          <div className='w-full h-full absolute top-0 left-0'>
            <img src={gif} alt="Gif" className={`object-cover w-full h-full`}/>  
          </div>

          <div className='w-[50%] aspect-square absolute top-10 rounded-full bg-white flex items-center justify-center'>
            <div className='box'></div>
          </div>

          <div className='absolute top-[45%] w-fit rounded-lg px-8 bg-black/40 backdrop-blur-sm flex items-center gap-2'>
            <span className='text-[80px] leading-[110px] mt-1'>18h</span>
            <div className='flex flex-col gap-2'>
              <span className='text-[40px] whitespace-nowrap leading-[30px]'>2 pistas</span>
              <span className='text-[40px] whitespace-nowrap leading-[30px]'>06 abril</span>
            </div>
          </div>

          <a 
            className='px-6 py-3 rounded-lg bg-white border z-10 absolute bottom-[120px]' 
            href={"https://gandaya.dance/events/javali" + (link ? "?link="+link : "")}
          >
            <span className='text-black font-[TrashHand] text-5xl whitespace-nowrap gradient-text'>
              ingressos
            </span>
          </a>

          <div className='absolute bottom-0 w-full bg-[#211F20] py-4 flex gap-2 items-center justify-center'>
          <span className='text-[#F2454E] font-[TrashHand] text-4xl whitespace-nowrap'>
            FABRIKETA
          </span>
          <span className='font-[PlatNomor] text-xl whitespace-nowrap'>rua do bucolismo, 81 - Bras</span>
        </div>
      </div>
    </div>
  );
}