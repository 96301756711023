import React from 'react';

import './index.css'

import { IphoneFrameMask } from "./components/IphoneFrameMask";


import { Home } from './pages/Home';

import {
	Routes,
	Route,
} from "react-router-dom";

const App = () => {
	return (
		<IphoneFrameMask>
			<Routes >
				<Route exact path="/" element={<Home />} />
			</Routes>
		</IphoneFrameMask>

	);
}

export default App;
